import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import { Test } from "./Test";
import * as serviceWorker from "./serviceWorker";
import { wp } from "./common/util";

console.log("app");
ReactDOM.render(<App />, document.getElementById("root"));
// ReactDOM.render(
//   <Obj3D {...{ glb: require("./phial.glb"), width: wp(20), height: wp(50) }} />,
//   document.getElementById("root")
// );
// ReactDOM.render(<Test />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
