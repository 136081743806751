import React from "react";
import "./index.css";

function App() {
  console.log("app");
  return (
    <div>
      <div id="content" className="team">
        <div className="people">
          <div className="name">Bruno Beauvais</div>
          <img src="images/brunobeauvais.png" className="photo" />{" "}
          <a href="https://www.linkedin.com/in/bruno-beauvais-b102a937/">
            <img className="linkedin" src="images/linkedin.png" />
          </a>
          <div className="role">
            Capitaine
            <br />
          </div>
        </div>
        <div className="people">
          <div className="name">Andreas Müller</div>
          <img src="images/andreasmulleur.png" className="photo" />{" "}
          <a href="https://www.linkedin.com/in/andreas-m%C3%BCller-6055335/">
            <img className="linkedin" src="images/linkedin.png" />
          </a>
          <div className="role">
            Navigateur
            <br />
          </div>
        </div>
        <div className="people">
          <div className="name">Elodie Martinez</div>
          <img src="images/elodiemartinez.png" className="photo" />{" "}
          <a href="https://www.mondocteur.fr/kinesitherapeute/lyon-7eme/martinez-elodie.html">
            <img className="linkedin" src="images/linkedin.png" />
          </a>
          <div className="role">
            Exploratrice
            <br />
          </div>
        </div>
      </div>
      <div id="footer">
        <div id="footerCenter">
          <img src="images/titleWhite.png" id="logoFooter" />
          <div id="footerRight">
            &copy; 2018 EQUIPAGE.TECH SASU
            <br />
            contact@equipage.tech
            <br />
            <br />
            +33 480 160 697
            <br />
            +33 782 122 487
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
